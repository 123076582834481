export const markersData = [
  {
    id: 1,
    name: "Tv2vie",
    lat: 48.624591,
    lon: 2.4422607,
    address: "24 Rue Charles Fourier, 91000 Évry-Courcouronnes",
    contry: "France",
    link: "https://www.tv2vie.org",
    icon: "imgMarker/marker-standard.png",
    iconSize: [25, 41],
    category: "biblecenter",
    imgStructure:
      "/structure/centres-bibliques/mve/img-mve/img-mve-structure.jpg",
  },
  {
    id: 2,
    name: "El Jireh Orphelinat",
    lat: 4.055167,
    lon: 9.762248,
    address: "Cité des palmiers - Face Louis Pasteur, Douala",
    contry: "Cameroom",
    link: "https://orphelinateljireh.org",
    icon: "imgMarker/marker-social.png",
    iconSize: [40, 41],
    category: "social",
    imgStructure:
      "/structure/centres-bibliques/mve/img-mve/img-mve-structure.jpg",
  },
  {
    id: 2,
    name: "Centre Hospitalier Pain Quotidien",
    lat: -4.171778,
    lon: 15.536437,
    address: "Avenue du fleuve No33 Ngamanzo C/Maluku Kinshasa RDC",
    contry: "Kinshasa RDC",
    link: "https://centrehospitalier.painquotidien.org",
    icon: "imgMarker/marker-hospital.png",
    iconSize: [38, 41],
    category: "hospital",
    imgStructure:
      "/structure/centres-bibliques/mve/img-mve/img-mve-structure.jpg",
  },
  {
    id: 3,
    name: "Mission Vivre Solidaire",
    lat: 46.7041969,
    lon: 0.4042562,
    address: "33 Allées des Érables, 86130 Dissay",
    contry: "France",
    link: "https://vivrelevangile.org",
    icon: "imgMarker/marker-social.png",
    iconSize: [40, 41],
    category: "social",
    imgStructure:
      "/structure/centres-bibliques/mve/img-mve/img-mve-structure.jpg",
  },
  {
    id: 4,
    name: "MVE",
    lat: 46.7041969,
    lon: 0.4042562,
    address: "33 Allées des Érables, 86130 Dissay",
    contry: "France",
    link: "https://vivrelevangile.org",
    icon: "imgMarker/marker-standard.png",
    iconSize: [25, 41],
    category: "biblecenter",
    imgStructure:
      "/structure/centres-bibliques/mve/img-mve/img-mve-structure.jpg",
  },
  {
    id: 5,
    name: " Centre Hospitalier d'Okolassi",
    lat: 0.3718517,
    lon: 9.6969501,
    address: "Okolassi",
    contry: "Gabon",
    link: "https://www.youtube.com/@gdbgabon1584",
    icon: "imgMarker/marker-hospital.png",
    iconSize: [38, 41],
    category: "hospital",
    imgStructure:
      "/structure/centres-bibliques/mve/img-mve/img-mve-structure.jpg",
  },
];
