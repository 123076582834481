// import React, { useEffect, useState, useRef } from "react";
// import "leaflet/dist/leaflet.css";
// import "bootstrap/dist/css/bootstrap.min.css";
// import { markersData } from "./TableOfStructures";
// import { MapContainer, TileLayer, Popup, Marker } from "react-leaflet";
// import L from "leaflet";

// const MapOfStructures = React.memo(() => {
//   const [zoomLevel, setZoomLevel] = useState(2);
//   const mapRef = useRef(null);

//   useEffect(() => {
//     const handleResize = () => {
//       const newZoomLevel = Math.max(
//         1,
//         Math.min(18, Math.floor(window.innerWidth / 430))
//       );
//       setZoomLevel(newZoomLevel);
//       if (mapRef.current) {
//         mapRef.current.setView(
//           window.innerWidth < 860 ? [0, 0] : [42, 0],
//           newZoomLevel
//         );
//       }
//     };

//     handleResize();

//     window.addEventListener("resize", handleResize);

//     return () => {
//       window.removeEventListener("resize", handleResize);
//     };
//   }, []);

//   const filterMarkers = () => {
//     if (selectedCategory) {
//       return markersData.filter(
//         (marker) => marker.category === selectedCategory
//       );
//     }
//     return markersData;
//   };

//   const resetCategory = () => {
//     setSelectedCategory(null);
//   };

//   console.log("Current Zoom Level:", zoomLevel);

//   return (
//     <div className="ContainerAssocaition">
//       <section className="sectionBtnMap">
//         <button className="btnMap" onclick="">
//           Centres bibliques
//         </button>
//         <button className="btnMap" id="btnMap-S" onclick="">
//           Sociales
//         </button>
//         <button className="btnMap" id="btnMap-H" onclick="">
//           Hopitaux
//         </button>
//       </section>
//       <MapContainer
//         ref={mapRef}
//         center={window.innerWidth < 860 ? [0, 0] : [42, 0]}
//         zoom={zoomLevel}
//         style={{ height: "100vh", width: "100%" }}
//       >
//         <TileLayer
//           url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
//           attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributeurs'
//           noWrap={false}
//         />
//         {markersData &&
//           markersData.map((marker, index) => (
//             <Marker
//               key={index}
//               position={[marker.lat, marker.lon]}
//               icon={
//                 L.Icon.Default
//                   ? new L.Icon({
//                       iconUrl: process.env.PUBLIC_URL + marker.icon,
//                       iconSize: marker.iconSize,
//                       iconAnchor: [12.5, 41],
//                       popupAnchor: [0, -35],
//                     })
//                   : null
//               }
//             >
//               <Popup>
//                 <article className="popup">
//                   <span>{marker.name}</span>
//                   <a
//                     href={marker.link}
//                     target="_blank"
//                     rel="noopener noreferrer"
//                   >
//                     {marker.link}
//                   </a>
//                 </article>
//               </Popup>
//             </Marker>
//           ))}
//         <article className="sLegendMap">
//           <article>
//             <div>
//               <img
//                 src={process.env.PUBLIC_URL + "/legendIcon/hospital.png"}
//                 alt=""
//                 style={{ width: "1.1rem" }}
//               />
//               <span>Hopitaux</span>
//             </div>
//             <div>
//               <img
//                 src={process.env.PUBLIC_URL + "/legendIcon/social.png"}
//                 alt=""
//                 style={{ width: "1.1rem" }}
//               />{" "}
//               <span>Sociales</span>
//             </div>
//             <div>
//               <img
//                 src={process.env.PUBLIC_URL + "/legendIcon/standard.png"}
//                 alt=""
//                 style={{
//                   width: "0.75rem",
//                   marginLeft: ".2rem",
//                   marginRight: ".6rem",
//                 }}
//               />{" "}
//               <span>Centres Bibliques</span>
//             </div>
//           </article>
//         </article>
//       </MapContainer>
//     </div>
//   );
// });

// export default MapOfStructures;

import React, { useEffect, useState, useRef } from "react";
import "leaflet/dist/leaflet.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { markersData } from "./TableOfStructures";
import { MapContainer, TileLayer, Popup, Marker } from "react-leaflet";
import L from "leaflet";

const MapOfStructures = React.memo(() => {
  const [zoomLevel, setZoomLevel] = useState(2);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const mapRef = useRef(null);

  useEffect(() => {
    const handleResize = () => {
      const newZoomLevel = Math.max(
        1,
        Math.min(18, Math.floor(window.innerWidth / 430))
      );
      setZoomLevel(newZoomLevel);
      if (mapRef.current) {
        mapRef.current.setView(
          window.innerWidth < 860 ? [0, 0] : [42, 0],
          newZoomLevel
        );
      }
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // useEffect(() => {
  //   // Donner le focus au bouton avec la classe initialBlue après le rendu initial
  //   const initialColorButton = document.querySelector(".btnMap");
  //   if (initialColorButton) {
  //     initialColorButton.focus();
  //   }
  // }, []);

  // useEffect(() => {
  //   const handleContainerClick = (e) => {
  //     const isButton = e.target.classList.contains("btnMap");

  //     if (!isButton) {
  //       // Empêcher la propagation de l'événement si le clic est en dehors des boutons
  //       e.stopPropagation();
  //     }
  //   };

  //   // Ajouter le gestionnaire d'événements sur le conteneur parent
  //   document
  //     .querySelector(".ContainerAssocaition")
  //     .addEventListener("mousedown", handleContainerClick);

  //   return () => {
  //     // Retirer le gestionnaire d'événements lors du démontage du composant
  //     document
  //       .querySelector(".ContainerAssocaition")
  //       .removeEventListener("mousedown", handleContainerClick);
  //   };
  // }, []);

  const filterMarkers = () => {
    if (selectedCategory) {
      return markersData.filter(
        (marker) => marker.category === selectedCategory
      );
    }

    return markersData;
  };

  const resetCategory = () => {
    setSelectedCategory(null);
  };

  return (
    <div className="ContainerAssocaition">
      <section className="sectionBtnMap">
        <button
          className={`btnMap ${selectedCategory === null ? "active" : ""}`}
          onClick={resetCategory}
        >
          Tous
        </button>
        <button
          className={`btnMap ${
            selectedCategory === "biblecenter" ? "active" : ""
          }`}
          id="btnMap-B"
          onClick={() => setSelectedCategory("biblecenter")}
        >
          Centres bibliques
        </button>
        <button
          className={`btnMap ${selectedCategory === "social" ? "active" : ""}`}
          id="btnMap-S"
          onClick={() => setSelectedCategory("social")}
        >
          Sociales
        </button>
        <button
          className={`btnMap ${
            selectedCategory === "hospital" ? "active" : ""
          }`}
          id="btnMap-H"
          onClick={() => setSelectedCategory("hospital")}
        >
          Hopitaux
        </button>
      </section>
      <MapContainer
        ref={mapRef}
        center={window.innerWidth < 860 ? [0, 0] : [42, 0]}
        zoom={zoomLevel}
        style={{ height: "100vh", width: "100%" }}
      >
        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributeurs'
          noWrap={false}
        />
        {filterMarkers().map((marker, index) => (
          <Marker
            key={index}
            position={[marker.lat, marker.lon]}
            icon={
              L.Icon.Default
                ? new L.Icon({
                    iconUrl: process.env.PUBLIC_URL + marker.icon,
                    iconSize: marker.iconSize,
                    iconAnchor: [12.5, 41],
                    popupAnchor: [0, -35],
                  })
                : null
            }
            eventHandlers={{
              mouseover: (e) => {
                e.target.openPopup();
              },
              // mouseout: (e) => {
              //   // Masquer le Popup lorsque le survol est terminé
              //   e.target.closePopup();
              // },
            }}
          >
            <Popup
              className="popup-container"
              eventHandlers={{
                mouseover: (e) => {
                  e.target.openPopup();
                },
                mouseout: (e) => {
                  e.target.closePopup();
                },
              }}
            >
              <article className="popup">
                <span>{marker.name}</span>
                <a href={marker.link} target="_blank" rel="noopener noreferrer">
                  {marker.link}
                </a>
              </article>
              <article>
                <img
                  className="imgStructure"
                  src={process.env.PUBLIC_URL + marker.imgStructure}
                  alt="structure"
                  // style={{ width: "100%", marginTop: "1rem" }}
                />
              </article>
            </Popup>
          </Marker>
        ))}
        <article className="sLegendMap">
          <article>
            <div>
              <img
                src={process.env.PUBLIC_URL + "/legendIcon/hospital.png"}
                alt=""
                style={{ width: "1.1rem" }}
              />
              <span>Hopitaux</span>
            </div>
            <div>
              <img
                src={process.env.PUBLIC_URL + "/legendIcon/social.png"}
                alt=""
                style={{ width: "1.1rem" }}
              />{" "}
              <span>Sociales</span>
            </div>
            <div>
              <img
                src={process.env.PUBLIC_URL + "/legendIcon/standard.png"}
                alt=""
                style={{
                  width: "0.75rem",
                  marginLeft: ".2rem",
                  marginRight: ".6rem",
                }}
              />{" "}
              <span>Centres Bibliques</span>
            </div>
          </article>
        </article>
      </MapContainer>
    </div>
  );
});

export default MapOfStructures;

// import React, { useEffect, useState, useRef } from "react";
// import "leaflet/dist/leaflet.css";
// import "bootstrap/dist/css/bootstrap.min.css";
// import { markersData } from "./TableOfStructures";
// import { MapContainer, TileLayer, Popup, Marker } from "react-leaflet";
// import L from "leaflet";
// import "leaflet.animatedmarker/src/AnimatedMarker";

// const MapOfStructures = React.memo(() => {
//   const [zoomLevel, setZoomLevel] = useState(2);
//   const [selectedCategory, setSelectedCategory] = useState(null);
//   const mapRef = useRef(null);
//   const animatedMarkerRef = useRef(null);
//   const polylineRef = useRef(null);
//   const animationDistance = 300;

//   useEffect(() => {
//     const handleResize = () => {
//       const newZoomLevel = Math.max(
//         1,
//         Math.min(18, Math.floor(window.innerWidth / 430))
//       );
//       setZoomLevel(newZoomLevel);
//       if (mapRef.current) {
//         mapRef.current.setView(
//           window.innerWidth < 860 ? [0, 0] : [42, 0],
//           newZoomLevel
//         );
//       }
//     };

//     handleResize();

//     window.addEventListener("resize", handleResize);

//     return () => {
//       window.removeEventListener("resize", handleResize);
//     };
//   }, []);

//   useEffect(() => {
//     // Donner le focus au bouton avec la classe initialBlue après le rendu initial
//     const initialBlueButton = document.querySelector(".btnMap");
//     if (initialBlueButton) {
//       initialBlueButton.focus();
//     }
//   }, []);

//   const resetCategory = () => {
//     setSelectedCategory(null);
//     clearAnimation();
//   };

//   const clearAnimation = () => {
//     // Efface la polyline et le marqueur animé existants
//     if (polylineRef.current) {
//       mapRef.current.removeLayer(polylineRef.current);
//     }
//     if (animatedMarkerRef.current) {
//       mapRef.current.removeLayer(animatedMarkerRef.current);
//     }
//   };

//   const filterMarkers = (shouldAnimate) => {
//     // Efface la catégorie sélectionnée et arrête l'animation avant de filtrer
//     clearAnimation();

//     let filteredMarkers = markersData;

//     if (selectedCategory) {
//       filteredMarkers = markersData.filter(
//         (marker) => marker.category === selectedCategory
//       );
//     }

//     // Animation logic
//     if (shouldAnimate) {
//       const polylineCoordinates = filteredMarkers.map((marker) => [
//         marker.lat,
//         marker.lon,
//       ]);

//       const line = L.polyline(polylineCoordinates);
//       polylineRef.current = line;

//       if (mapRef.current) {
//         line.addTo(mapRef.current);

//         animatedMarkerRef.current = L.animatedMarker(line.getLatLngs());
//         animatedMarkerRef.current.addTo(mapRef.current);
//         animatedMarkerRef.current.options.distance = animationDistance;
//         animatedMarkerRef.current.start();

//         setTimeout(() => {
//           animatedMarkerRef.current.stop();
//         }, 20000);
//       }
//     }

//     return filteredMarkers;
//   };

//   // Utilisation de useEffect pour déclencher le filtrage initial
//   useEffect(() => {
//     filterMarkers(true);
//   }, [filterMarkers]);

//   return (
//     <div className="ContainerAssocaition">
//       <section className="sectionBtnMap">
//         <button className="btnMap initialBlue" onClick={resetCategory}>
//           Tous
//         </button>
//         <button
//           className="btnMap"
//           id="btnMap-B"
//           onClick={() => {
//             setSelectedCategory("biblecenter");
//             filterMarkers(true);
//           }}
//         >
//           Centres bibliques
//         </button>
//         <button
//           className="btnMap"
//           id="btnMap-S"
//           onClick={() => {
//             setSelectedCategory("social");
//             filterMarkers(true);
//           }}
//         >
//           Sociales
//         </button>
//         <button
//           className="btnMap"
//           id="btnMap-H"
//           onClick={() => {
//             setSelectedCategory("hostipal");
//             filterMarkers(true);
//           }}
//         >
//           Hopitaux
//         </button>
//       </section>
//       <MapContainer
//         ref={mapRef}
//         center={window.innerWidth < 860 ? [0, 0] : [42, 0]}
//         zoom={zoomLevel}
//         style={{ height: "100vh", width: "100%" }}
//       >
//         <TileLayer
//           url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
//           attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributeurs'
//           noWrap={false}
//         />
//         {filterMarkers(false).map((marker, index) => (
//           <Marker
//             key={index}
//             position={[marker.lat, marker.lon]}
//             icon={
//               L.Icon.Default
//                 ? new L.Icon({
//                     iconUrl: process.env.PUBLIC_URL + marker.icon,
//                     iconSize: marker.iconSize,
//                     iconAnchor: [12.5, 41],
//                     popupAnchor: [0, -35],
//                   })
//                 : null
//             }
//           >
//             <Popup>
//               <article className="popup">
//                 <span>{marker.name}</span>
//                 <a href={marker.link} target="_blank" rel="noopener noreferrer">
//                   {marker.link}
//                 </a>
//               </article>
//             </Popup>
//           </Marker>
//         ))}
//         <article className="sLegendMap">
//           <article>
//             <div>
//               <img
//                 src={process.env.PUBLIC_URL + "/legendIcon/hospital.png"}
//                 alt=""
//                 style={{ width: "1.1rem" }}
//               />
//               <span>Hopitaux</span>
//             </div>
//             <div>
//               <img
//                 src={process.env.PUBLIC_URL + "/legendIcon/social.png"}
//                 alt=""
//                 style={{ width: "1.1rem" }}
//               />{" "}
//               <span>Sociales</span>
//             </div>
//             <div>
//               <img
//                 src={process.env.PUBLIC_URL + "/legendIcon/standard.png"}
//                 alt=""
//                 style={{
//                   width: "0.75rem",
//                   marginLeft: ".2rem",
//                   marginRight: ".6rem",
//                 }}
//               />{" "}
//               <span>Centres Bibliques</span>
//             </div>
//           </article>
//         </article>
//       </MapContainer>
//     </div>
//   );
// });
// export default MapOfStructures;
