import React from "react";
import MapOfStructures from "../subcomponent/MapOfStructures";

const Associations = () => {
  return (
    <div>
      <MapOfStructures />
    </div>
  );
};

export default Associations;
