import React from "react"; // Don't forget to import React
import { Routes, Route } from "react-router-dom";
// import Home from "./component/Home";
import Associations from "./component/Associations";
import Contact from "./component/Contact";

function App() {
  return (
    <div className="App">
      <Routes>
        {/* <Route path="" element={<Home />} /> */}
        <Route path="/" element={<Associations />} />
        <Route path="/contact" element={<Contact />} />
      </Routes>
    </div>
  );
}

export default App;
